import { createStore } from 'vuex'
import createPersistedState from 'vuex-persistedstate'

export default createStore({
	plugins: [createPersistedState({
		storage: window.sessionStorage,
	})],
	state: {
		user: null,
		isLoggedIn: false
	},
	mutations: {
		setUserData(state, udata) {
			state.user = udata;
			state.isLoggedIn = state.user != null;
		}
	},
	actions: {
	},
	getters: {
	// method name must be the same as the property //
		isAdmin: state => {
			return state.user && state.user.isAdmin;
		},
		isLoggedIn: state => {
			return state.isLoggedIn;
		}
	},
	modules: {
	}
})
