<template lang='pug'>
div.page-wrap
	h1 Hello
	router-link(:to="{ path: '/login' }")#login Login
</template>

<script>

export default{
	created(){
	// redirect to dashboard if session hasn't expired //
		fetch('/authenticate', {credentials: 'include'})
		.then(response => response.json())
		.then(response => {
			this.$store.commit('setUserData', response.account);
			if (response.account != null){
				this.$router.push('/admin/dashboard');
			}
		});
	}
}

</script>

<style lang="stylus" scoped>
h1
	margin-bottom 40px
#login
	display inline-block
	padding 24px 60px
	border 1px solid #eee
	font-size .8rem
	color #777
	font-weight bold
	transition .3s
	text-transform uppercase
	&:hover
		color red
		border 1px solid red
</style>