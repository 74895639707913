
import moment from "moment"
import States from './States'

 export default {
	mixins:[States],
	methods:{
		date(date) {
			return moment(date).format('MMMM Do YYYY');
		},
		dateAndTime(date) {
			return moment(date).format('MMMM Do YYYY, h:mm:ss A');
		},
		getStateKeyFromName(name)
		{
			return states.getKeyFromName(name);
		},
		duration(v)
		{
			let duration = 0;
			v.sessions.forEach(session => {
				duration += session.duration;
			});
			return this.secsToMins(duration);
		},
		secsToMins(duration){
			let m = Math.floor(duration/60)
			let s = Math.round(duration % 60);
			if (s < 10) s = '0'+s;
			if (m < 10) m = '0'+m;
			return m+':'+s;
		},
		location(ip){
			let l = ip.city+', ';
			if (ip.country === 'United States') {
				l += this.getKeyFromName(ip.region);
			}	else{
				l += ip.region;
				l += ', '+ip.country;
			}
			return l;
		},
		timeAgo(date)
		{
			return moment(date).fromNow();
		},
		siteName(url)
		{
			return url.replace(/^https?:\/\//, '')
		},
		toTitleCase(str)
		{
			return str.replace(/\w\S*/g, (txt) => {
				return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
			});
		},
		// getPast30Days(){
		// 	let i = 30;
		// 	let today = moment();
		// 	let days = [today.format('MMM Do')]
		// 	while(i-- > 0){
		// 		today = today.subtract(1, 'days');
		// 		days.push(today.format('MMM Do'));
		// 	}
		// 	return days;
		// },
		// datePath(date) {
		// 	date = new Date(date);
		// 	let y = date.getFullYear();
		// 	let m = date.getMonth() + 1;
		// 	let d = date.getDate();
		// 	return "/admin/property/" + this.property.key +"/" + m + "-" + d + "-" + y;
		// },
	}

 }