export default {
	data() {
		return {
			states : [
				{ key: "AL", name: "Alabama" },
				{ key: "AK", name: "Alaska" },
				{ key: "AZ", name: "Arizona" },
				{ key: "AR", name: "Arkansas" },
				{ key: "CA", name: "California" },
				{ key: "CO", name: "Colorado" },
				{ key: "CT", name: "Connecticut" },
				{ key: "DE", name: "Delaware" },
				{ key: "DC", name: "District Of Columbia" },
				{ key: "FL", name: "Florida" },
				{ key: "GA", name: "Georgia" },
				{ key: "HI", name: "Hawaii" },
				{ key: "ID", name: "Idaho" },
				{ key: "IL", name: "Illinois" },
				{ key: "IN", name: "Indiana" },
				{ key: "IA", name: "Iowa" },
				{ key: "KS", name: "Kansas" },
				{ key: "KY", name: "Kentucky" },
				{ key: "LA", name: "Louisiana" },
				{ key: "ME", name: "Maine" },
				{ key: "MD", name: "Maryland" },
				{ key: "MA", name: "Massachusetts" },
				{ key: "MI", name: "Michigan" },
				{ key: "MN", name: "Minnesota" },
				{ key: "MS", name: "Mississippi" },
				{ key: "MO", name: "Missouri" },
				{ key: "MT", name: "Montana" },
				{ key: "NE", name: "Nebraska" },
				{ key: "NV", name: "Nevada" },
				{ key: "NH", name: "New Hampshire" },
				{ key: "NJ", name: "New Jersey" },
				{ key: "NM", name: "New Mexico" },
				{ key: "NY", name: "New York" },
				{ key: "NC", name: "North Carolina" },
				{ key: "ND", name: "North Dakota" },
				{ key: "OH", name: "Ohio" },
				{ key: "OK", name: "Oklahoma" },
				{ key: "OR", name: "Oregon" },
				{ key: "PA", name: "Pennsylvania" },
				{ key: "PR", name: "Puerto Rico" },
				{ key: "RI", name: "Rhode Island" },
				{ key: "SC", name: "South Carolina" },
				{ key: "SD", name: "South Dakota" },
				{ key: "TN", name: "Tennessee" },
				{ key: "TX", name: "Texas" },
				{ key: "UT", name: "Utah" },
				{ key: "VT", name: "Vermont" },
				{ key: "VA", name: "Virginia" },
				{ key: "WA", name: "Washington" },
				{ key: "WV", name: "West Virginia" },
				{ key: "WI", name: "Wisconsin" },
				{ key: "WY", name: "Wyoming" }
			]
		}
	},
	methods:{
		getKeyFromName(name) {
			for (const state of this.states) {
				if (name.toLowerCase() === state.name.toLowerCase()) return state.key;
			}
			return '';
		},
		getNameFromKey(key) {
			for (const state of this.states) {
				if (key.toLowerCase() === state.key.toLowerCase()) return state.name;
			}
			return '';
		},
		validateStateKey(key) {
			for (const s of this.states) if (key.toUpperCase() === s.key) return true;
			return false;
		}
	}
}
