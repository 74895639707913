<template lang="pug">
header
	.navBtn(@click="$emit('showNav')")
		img(src='/img/gfx/mobile_nav.svg')
	.pSelect(@click='showPropertySelector') {{label}}
	.settings(v-if='$route.params.key' @click='onSettingsClicked')
		img(src='/img/gfx/settings.svg')
</template>

<script>

export default{
	data(){
		return {
			label: '',
			properties: []
		}
	},
	watch: {
		'$route': function(route) {
			if (this.properties.length) this.setHeaderLabel();
		}
	},
	async mounted(){
		const res = await fetch('/api/v1/admin', {credentials: 'include'})
		.then(response => response.json())
		.then(json => {
			this.properties = json.properties;
			this.setHeaderLabel();
		});
	},
	methods:{
		showPropertySelector()
		{
			console.log('showPropertySelector');
		},
		onSettingsClicked()
		{
			this.$router.push('/admin/property/'+this.$route.params.key+'/settings');
		},
		setHeaderLabel()
		{
			let key = this.$route.params.key;
			let path = this.$route.path;
			if (key){
				this.properties.forEach(p => {
					if (p.key === key) this.label = p.name;
				})
			}	else{
				this.label = path.slice(path.lastIndexOf('/') + 1);
			}
		}
	}
}

</script>

<style lang="stylus" scoped>
header
	height 80px
	width 100%
	z-index 9999
	background #fff
	top 0; left 0
	position fixed
	border-bottom 1px solid #eee
	.navBtn, .settings
		width 60px
		height 100%
		cursor pointer
		position absolute; top 0;
		img
			height 30%
			position absolute
			top 50%; left 50%
			transform translate(-50%, -50%)
	.navBtn
		left 0
	.settings
		right 0
	.pSelect
		position absolute
		font-weight bold
		top 50%; left 50%
		transform translate(-50%, -50%)
</style>