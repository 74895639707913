<template lang="pug">
nav.nav(:class='{visible : isVisible}')
	.navItem(v-for='item in navItems' :data-id='item.label' @click='onNavItemClicked')
		img(:src="'/img/gfx/'+item.icon")
		label {{toTitleCase(item.label)}}
</template>

<script>

import Utils from "../mixins/Utils";

export default{

	data(){
		return{
			navItems : [
				{label:'dashboard', icon:'dashboard.svg'},
				{label:'account', icon:'mobile_nav.svg'},
				{label:'backup', icon:'backup.svg'},
				{label:'restore', icon:'restore.svg'},
				{label:'logout', icon:'logout.svg'}
			],
			isVisible:false
		}
	},
	mixins:[Utils],
	methods:{
		show(){
			this.isVisible = true;
		},
		hide(){
			this.isVisible = false
		},
		onNavItemClicked(e)
		{
			console.log('onNavItemClicked', e.currentTarget.dataset.id);
			switch(e.currentTarget.dataset.id){
				case 'dashboard' : this.$router.push('/admin/dashboard'); break;
				case 'account' : alert('account stuff coming soon'); break;
				case 'backup' :  this.onBackupDatabase(); break;
				case 'restore' :  this.onRestoreDatabase(); break;
				case 'logout' :  this.onLogoutClicked(); this.hide(); break;
			}
			setTimeout(this.hide, 500);
		},
		onWindowClicked(e)
		{
			let ignore = ['nav', 'navBtn', 'navItem']; // navBtn is in header //
			let t = e.target;
			let hide = true;
			while(t != undefined){
				ignore.forEach(c => {
					if (t.classList.contains(c)) hide = false;
				});
				t = t.parentElement;
			}
			if (hide) this.hide();
		},
		async onBackupDatabase()
		{
			await fetch('/admin/backup', { credentials: 'include' })
			.then(response => response.text())
			.then(text => {
				if (text == 'ok'){
					alert('database successfully backed up');
					this.$router.push('/admin/dashboard');
				}	else{
					alert('there was a problem');
				}
			});
		},
		async onRestoreDatabase()
		{
			if (confirm('Are you sure you want to restore the database from the last backup?')){
				await fetch('/admin/restore', { credentials: 'include' })
				.then(response => response.text())
				.then(text => {
					if (text == 'ok'){
						alert('database successfully restored');
						this.$router.push('/admin/dashboard');
					}	else{
						alert('there was a problem');
					}
				});
			}
		},
		onLogoutClicked(){
			fetch('/logout', {credentials: 'include'})
			.then(response => {
				window.sessionStorage.clear();
				this.$store.commit('setUserData', null);
				this.$router.push('/');
			});
		},
	},
	created () {
		window.addEventListener('click', this.onWindowClicked);
	},
	destroyed () {
		window.removeEventListener('click', this.onWindowClicked);
	}

}

</script>

<style lang="stylus" scoped>

nav
	height 100%
	z-index 9998
	background white
	border-right 1px solid #eee
	position fixed
	top 80px; left 0
	padding 20px
	transform translateX(-100%)
	transition transform .4s ease
	.navItem
		display flex
		margin-bottom 2vh
		align-items center
		cursor pointer
		transition .3s
		padding 1vh 0
		//background #eee
		img
			height 24px
			//background green
		label
			cursor inherit
			margin 0 0 0 8px
			text-transform none
		&:hover
			label
				color red
nav.visible
	transform translateX(0)

</style>