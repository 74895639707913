<template lang='pug'>
div
	Header(v-if='isLoggedIn' v-on:show-nav='onShowNav')
	SideNav(ref='nav')
	router-view(id='content' v-slot="{ Component }")
		transition(name="fade" mode='out-in')
		component(:is="Component")
</template>

<script>

import Header from './components/Header'
import SideNav from './components/SideNav'

export default{
	components:{ Header, SideNav },
	methods:{
		onShowNav(){
			this.$refs.nav.show();
		}
	},
	computed:{
		isLoggedIn:function(){
			return this.$store.state.isLoggedIn;
		},
	},
}

</script>

<style lang="stylus">

@import './assets/css/core.styl';

</style>
