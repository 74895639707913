import { createRouter, createWebHistory } from 'vue-router'
import store from '../store/index'
import Home from '../views/Home.vue'

const routes = [
	{
		path: '/',
		component: Home
	},
	{
		path: '/login',
		component: () => import(/* webpackChunkName: "login" */ '../views/Login.vue')
	},
	{
		path: '/admin/dashboard',
		meta: { requiresAuth: true },
		component: () => import(/* webpackChunkName: "dashboard" */ '../views/Dashboard.vue')
	},
	{
		path: '/admin/property/:key',
		meta: { requiresAuth: true },
		component: () => import(/* webpackChunkName: "summary" */ '../views/property/Summary.vue')
	},
	{
		path: '/admin/property/:key/settings',
		meta: { requiresAuth: true },
		component: () => import(/* webpackChunkName: "summary" */ '../views/property/Settings.vue')
	},
	{
		path: '/admin/property/:key/pages',
		meta: { requiresAuth: true },
		component: () => import(/* webpackChunkName: "pages" */ '../views/property/PagesView.vue')
	},
	{
		path: '/admin/property/:key/page/:path*',
		meta: { requiresAuth: true },
		component: () => import(/* webpackChunkName: "page" */ '../views/property/PageDetails.vue')
	},
	{
		path: '/admin/property/:key/ip/:ipa',
		meta: { requiresAuth: true },
		component: () => import(/* webpackChunkName: "ipview" */ '../views/property/IpView.vue')
	},
	{
		path: '/admin/property/:key/:date',
		meta: { requiresAuth: true },
		component: () => import(/* webpackChunkName: "dayview" */ '../views/property/DayView.vue')
	}
]

const router = createRouter({
	history: createWebHistory(process.env.BASE_URL),
	routes
})

router.beforeEach((to, from, next) => {
	if (to.matched.some(record => record.meta.requiresAuth)) {
		fetch('/authenticate', {credentials: 'include'})
		.then(response => response.json())
		.then(response => {
			if (response.account != null){
			// ensure header shows after deploying an update //
				if (store.state.isLoggedIn === false){
					store.commit('setUserData', response.account);
				}
				next();
			}	else{
				next({path: '/login', params: {nextUrl: to.fullPath}});
			}
		}).catch((e) => {
			console.log(e);
		});
	}	else {
		next();
	}
})

export default router